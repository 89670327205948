import { IdeaDto, IdeaXAxis } from "api-shared";
import { useMemo } from "react";

export function useIdeaMatrixFilter(ideas: IdeaDto[], xAxis: IdeaXAxis) {
    const visibleIdeas = useMemo(
        () => ideas.filter((idea) => idea.potentialEstimate !== null && idea[xAxis] !== null) ?? [],
        [ideas, xAxis],
    );

    const numberOfHiddenIdeas = ideas.length - visibleIdeas.length;

    return {
        visibleIdeas,
        numberOfHiddenIdeas,
    };
}
