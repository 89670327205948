import { Box, CircularProgress, SxProps, Theme, styled, useTheme } from "@mui/material";
import { EstimatesValue, IdeaDto, IdeaFieldNames, IdeaXAxis } from "api-shared";
import { groupBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CartesianGrid,
    Cell,
    Label,
    LabelList,
    ReferenceArea,
    ReferenceLine,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
    ZAxis,
} from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import { translationKeys } from "../../../translations/main-translations";
import IdeaMatrixScatterTooltip from "./IdeaMatrixScatterTooltip";

const scatterClassName = "VdScatterPointClickTarget";
const scatterLabelClassName = "VdScatterPointLabel";
const scatterScalingBaseValue = 800;
const scatterScalingMaxValue = 3200;
const scatterScalingIncrement = 300;

const referenceAreaOpacity = 0.04;
export const CHART_BORDER_OFFSET = 5;

const getScatterScalingUpperLimit = (maxValue: number) => {
    // scaling between scatterScalingBaseValue and scatterScalingMaxValue
    const upperLimitCalculated = scatterScalingBaseValue + (maxValue - 1) * scatterScalingIncrement;
    // take max value or calculated value
    return upperLimitCalculated > scatterScalingMaxValue ? scatterScalingMaxValue : upperLimitCalculated;
};

export enum IdeaProperties {
    POTENTIAL = "potentialEstimate",
    TIME = "timeEstimate",
    EFFORT = "effortEstimate",
    NUMBER_OF_IDEAS = "numberOfIdeas",
}

const PrimaryCaptionLabel = styled(Label)(({ theme }) => ({
    fill: theme.palette.text.primary,
    ...theme.typography.caption,
}));

// Need to set displayName to customize recharts components with styled
// https://github.com/recharts/recharts/issues/2694#issuecomment-979323998
PrimaryCaptionLabel.displayName = Label.displayName;

const SecondaryCaptionLabel = styled(Label)(({ theme }) => ({
    fill: theme.palette.text.secondary,
    ...theme.typography.caption,
}));

// Need to set displayName to customize recharts components with styled
// https://github.com/recharts/recharts/issues/2694#issuecomment-979323998
SecondaryCaptionLabel.displayName = Label.displayName;

interface IdeaMatrixProps {
    ideas: IdeaDto[];
    isFetching: boolean;
    xAxis: IdeaXAxis;
    chartHeight?: number | string;
    className?: string;
    sx?: SxProps<Theme>;
}

const groupByKeys =
    <T, K extends keyof T>(...keys: K[]) =>
    (item: T) => {
        const group: Partial<T> = {};
        keys.forEach((key) => {
            group[key] = item[key];
        });
        return JSON.stringify(group);
    };

const OppsScatterChart = styled(ScatterChart)(({ theme }) => ({
    [`& .${scatterClassName}`]: {
        // use hard coded shadows here for the svg bubbles - no reuse of this type of shadows so far
        cursor: "pointer",
        stroke: theme.palette.divider,
        filter: `drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.08)) `,
        fill: theme.palette.common.white,
        "&:hover": {
            filter: `drop-shadow(0px 2px 4px rgba(11, 60, 93, 0.12))`,
            fill: theme.palette.grey[50],
        },
    },
    [`& .${scatterLabelClassName}`]: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        fill: theme.palette.text.primary,
        // disable pointer events for label => scatter point tooltip is opened when clicking label
        pointerEvents: "none",
    },
}));

const LoadingContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "calc(50% - 10px)",
    left: "calc(50% - 20px)",
    transform: "translateY(-50%)",
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "50%",
    padding: theme.spacing(),
}));

const StyledCircularProgress = styled(CircularProgress)({ display: "block" });

// Embed a loading animation into a bubble that looks similar to the scatter bubbles and is ~centered in the chart
const BubbleLoadingAnimation = () => (
    <LoadingContainer>
        <StyledCircularProgress />
    </LoadingContainer>
);

export const IdeaMatrix = ({ ideas, isFetching, xAxis, chartHeight, className, sx }: IdeaMatrixProps) => {
    const yAxis = IdeaFieldNames.PotentialEstimate;

    const { t: translate } = useTranslation();
    const theme = useTheme();
    const [showTooltip, setShowTooltip] = useState(false);

    const data = useMemo(() => {
        const groups = groupBy(ideas, groupByKeys<IdeaDto, keyof IdeaDto>(yAxis, xAxis));

        return Object.values(groups)
            .filter((ideas) => ideas.length > 0) // hide bubbles with 0
            .map((ideas) => ({
                ideas,
                numberOfIdeas: ideas.length,
                ...ideas[0], // provide the group's values for x and y axis
            }));
    }, [ideas, xAxis, yAxis]);

    const isSortByTime = xAxis === IdeaXAxis.TIME;
    const xAxisLowLabel = isSortByTime
        ? translationKeys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_LOW
        : translationKeys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_LOW;
    const xAxisHighLabel = isSortByTime
        ? translationKeys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_HIGH
        : translationKeys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_HIGH;
    const xAxisLabel = isSortByTime
        ? translationKeys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE
        : translationKeys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE;

    const yAxisLowLabel = translationKeys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_LOW;
    const yAxisLabel = translationKeys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE;
    const yAxisHighLabel = translationKeys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_HIGH;

    const labelBaseProps = {
        fillOpacity: 0.5,
        fontSize: theme.typography.h6.fontSize,
        fontFamily: theme.typography.h6.fontFamily,
        fontStyle: theme.typography.h6.fontStyle,
        fontWeight: theme.typography.fontWeightMedium,
    };

    return (
        <Box sx={{ ...sx, position: "relative" }} className={className}>
            {isFetching ? <BubbleLoadingAnimation /> : null}
            <ResponsiveContainer height={chartHeight}>
                <OppsScatterChart margin={{}}>
                    <CartesianGrid horizontal={false} vertical={false} />
                    <ReferenceArea
                        x1={EstimatesValue.VERY_LOW - CHART_BORDER_OFFSET}
                        x2={EstimatesValue.MEDIUM}
                        y1={EstimatesValue.VERY_LOW - CHART_BORDER_OFFSET}
                        y2={EstimatesValue.MEDIUM}
                        fill={theme.palette.warning.main}
                        fillOpacity={referenceAreaOpacity}
                        label={{
                            value: translate(translationKeys.VDLANG_IDEAS_MATRIX_SECTION_WHEN_TIME),
                            fill: theme.palette.warning.main,
                            ...labelBaseProps,
                        }}
                    />
                    <ReferenceArea
                        x1={EstimatesValue.MEDIUM}
                        x2={EstimatesValue.VERY_HIGH + CHART_BORDER_OFFSET}
                        y1={EstimatesValue.VERY_LOW - CHART_BORDER_OFFSET}
                        y2={EstimatesValue.MEDIUM}
                        fill={theme.palette.error.main}
                        fillOpacity={referenceAreaOpacity}
                        label={{
                            value: translate(translationKeys.VDLANG_IDEAS_MATRIX_SECTION_SKIP),
                            fill: theme.palette.error.main,
                            ...labelBaseProps,
                        }}
                    />
                    <ReferenceArea
                        x1={EstimatesValue.MEDIUM}
                        x2={EstimatesValue.VERY_HIGH + CHART_BORDER_OFFSET}
                        y1={EstimatesValue.MEDIUM}
                        y2={EstimatesValue.VERY_HIGH + CHART_BORDER_OFFSET}
                        fill={theme.palette.primary.main}
                        fillOpacity={referenceAreaOpacity}
                        label={{
                            value: translate(translationKeys.VDLANG_IDEAS_MATRIX_SECTION_FUTURE),
                            fill: theme.palette.primary.main,
                            ...labelBaseProps,
                        }}
                    />
                    <ReferenceArea
                        x1={EstimatesValue.VERY_LOW - CHART_BORDER_OFFSET}
                        x2={EstimatesValue.MEDIUM}
                        y1={EstimatesValue.MEDIUM}
                        y2={EstimatesValue.VERY_HIGH + CHART_BORDER_OFFSET}
                        fill={theme.palette.natureGreen.main}
                        fillOpacity={referenceAreaOpacity}
                        label={{
                            value: translate(translationKeys.VDLANG_IDEAS_MATRIX_SECTION_QUICKWIN),
                            fill: theme.palette.natureGreen.main,
                            ...labelBaseProps,
                        }}
                    />
                    <ReferenceLine x={EstimatesValue.MEDIUM} strokeDasharray="4 2" />
                    <ReferenceLine y={EstimatesValue.MEDIUM} strokeDasharray="4 2" />
                    <XAxis
                        type="number"
                        dataKey={xAxis}
                        tick={false}
                        domain={[EstimatesValue.VERY_LOW - CHART_BORDER_OFFSET, EstimatesValue.VERY_HIGH + CHART_BORDER_OFFSET]}
                        height={18}
                    >
                        <PrimaryCaptionLabel value={translate(xAxisLowLabel)} position="insideLeft" />
                        <SecondaryCaptionLabel value={translate(xAxisLabel)} position="center" />
                        <PrimaryCaptionLabel value={translate(xAxisHighLabel)} position="insideRight" />
                    </XAxis>
                    <YAxis
                        type="number"
                        dataKey={yAxis}
                        tick={false}
                        domain={[EstimatesValue.VERY_LOW - CHART_BORDER_OFFSET, EstimatesValue.VERY_HIGH + CHART_BORDER_OFFSET]}
                        width={18}
                    >
                        {/* 
                             The dx values are a fix for the automatic dy value that rechart adds on text tspan elements. 
                             The dynamic dy tspan prop is normally used to position multiline texts vertically correctly, factoring in line height and capital char size. 
                             Due to their vertical alignment all three labels have a different vertical anchor and therefore a different calculation for the dy value.
                             In this case this causes the lables to be missaligned with each other.
                             See: https://github.com/recharts/recharts/blob/v2.7.3/src/component/Text.tsx#L235
                             */}
                        <PrimaryCaptionLabel dx={-8.5} value={translate(yAxisHighLabel)} position="insideTop" angle={-90} offset={25} />
                        <SecondaryCaptionLabel dx={-4} value={translate(yAxisLabel)} position="center" angle={-90} />
                        <PrimaryCaptionLabel value={translate(yAxisLowLabel)} position="insideBottom" angle={-90} offset={25} />
                    </YAxis>
                    <ZAxis
                        type="number"
                        dataKey={IdeaProperties.NUMBER_OF_IDEAS}
                        range={[
                            scatterScalingBaseValue,
                            getScatterScalingUpperLimit(Math.max(...data.map((point) => point.numberOfIdeas))),
                        ]}
                    />
                    <Scatter
                        data={data}
                        isAnimationActive={false}
                        // recharts stops the onClick event => we use onClickCapture here!
                        onClickCapture={() => setShowTooltip(true)}
                    >
                        {data.map((idea) => (
                            <Cell key={`scatter_cell_${idea.id}`} className={scatterClassName} />
                        ))}
                        <LabelList dataKey={IdeaProperties.NUMBER_OF_IDEAS} className={scatterLabelClassName} />
                    </Scatter>
                    <Tooltip
                        trigger="click"
                        isAnimationActive={false}
                        cursor={showTooltip}
                        content={
                            <IdeaMatrixScatterTooltip
                                isVisible={showTooltip}
                                targetClassName={scatterClassName}
                                toggleVisibility={() => setShowTooltip((prevVal) => !prevVal)}
                                selectedXAxis={xAxis}
                            />
                        }
                    />
                </OppsScatterChart>
            </ResponsiveContainer>
        </Box>
    );
};
