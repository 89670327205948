import { Popover, formControlClasses, styled } from "@mui/material";
import { IdeaStatus } from "api-shared";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import Select from "../../components/input/select/Select";
import SelectButton from "../../components/input/select/SelectButton";
import SelectStaticMenuContainer from "../../components/input/select/components/SelectStaticMenu";
import { Option } from "../../components/input/select/types";
import useMenu from "../../hooks/useMenu";
import { translationKeys } from "../../translations/main-translations";

const PopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(30),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

type IdeaStatusFilterProps = {
    value: IdeaStatus[];
    onChange?: (value: IdeaStatus[]) => void;
    size?: "small" | "medium";
    disabled?: boolean;
    fullWidth?: boolean;
};

export const IdeaStatusFilter = ({ value, onChange, size = "medium", disabled = false, fullWidth }: IdeaStatusFilterProps) => {
    const { t: translate } = useTranslation();
    const menu = useMenu();

    const label = translationKeys.VDLANG_IDEAS_SEARCH_STATUS;
    const options: Option<IdeaStatus>[] = [
        { value: IdeaStatus.OPEN, label: translate(translationKeys.VDLANG_IDEAS_OPEN) },
        { value: IdeaStatus.CONVERTED, label: translate(translationKeys.VDLANG_IDEAS_CONVERTED) },
        { value: IdeaStatus.DISCARDED, label: translate(translationKeys.VDLANG_IDEAS_DISCARDED) },
    ];

    const selectedOptions = options.filter((option) => value.includes(option.value));

    const handleChange = (newValue: OnChangeValue<Option, true>) => {
        onChange?.(newValue.map((option) => option.value));
    };

    return (
        <>
            <Popover {...menu.menuProps}>
                <PopoverContainer>
                    <Select
                        menuIsOpen={!disabled}
                        isSearchable
                        isMulti
                        size="small"
                        smallGutters
                        value={selectedOptions}
                        options={options}
                        onChange={handleChange}
                        margin="none"
                        placeholder={`${translate("Search")}...`}
                        isDisabled={disabled}
                        components={{
                            Menu: SelectStaticMenuContainer,
                            DropdownIndicator: undefined,
                        }}
                    />
                </PopoverContainer>
            </Popover>
            <SelectButton
                label={translate(label)}
                count={value.length}
                size={size}
                onClick={menu.openMenu}
                aria-expanded={menu.menuProps.open ? "true" : "false"}
                aria-haspopup="true"
                disabled={disabled && value.length === 0}
                fullWidth={fullWidth}
            />
        </>
    );
};
