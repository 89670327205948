import { Popover, formControlClasses, styled } from "@mui/material";
import { AttributeTable, IdeaAttributeDto, nonNullable } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import Select from "../../components/input/select/Select";
import SelectButton from "../../components/input/select/SelectButton";
import SelectStaticMenuContainer from "../../components/input/select/components/SelectStaticMenu";
import { Option } from "../../components/input/select/types";
import FieldTreeInput from "../../components/input/tree/FieldTreeInput";
import useFieldData from "../../hooks/useFieldData";
import useMenu from "../../hooks/useMenu";
import { translationKeys } from "../../translations/main-translations";
import TreeProvider from "../TreeProvider";

const PopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(30),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

type Props = {
    value: (string | null)[];
    attribute: IdeaAttributeDto;
    onChange?: (value: (string | null)[]) => void;
    size?: "small" | "medium";
    disabled?: boolean;
    fullWidth?: boolean;
};

const IdeaFieldFilter = ({ value, attribute, onChange, size = "medium", disabled = false, fullWidth }: Props) => {
    const { t: translate } = useTranslation();

    const menu = useMenu();

    const field = {
        ...attribute,
        options: attribute.options ?? {},
        value,
    };

    const [data] = useFieldData([field]);

    const options: Option<number | null>[] = data.map((option) => ({
        value: option.id,
        label: option.label,
    }));

    options.unshift({
        value: null,
        label: translate(translationKeys.VDLANG_IDEAS_FIELDS_NOT_SET),
    });

    const handleChange = (newValue: OnChangeValue<Option<number | number[] | null>, true>) => {
        if (onChange === undefined) {
            return;
        }

        const values = newValue.map((option) => option.value).map((v) => (v !== null ? String(v) : null));

        onChange(values);
    };

    const handleTreeSelectChange = (newValue: number[] | number | null) => {
        const sanitizedValue = [newValue].flat().filter(nonNullable).map(String);
        onChange?.(sanitizedValue);
    };

    const selectedOptions = options.filter((option) => value.includes(option.value !== null ? String(option.value) : null));

    let valueAsArray: number[] = [];
    if (Array.isArray(value)) {
        valueAsArray = value.map(Number);
    } else if (value != null) {
        valueAsArray = [+value];
    }

    if (field.tableName === AttributeTable.TreeNodes) {
        // FieldTreeInput has its own resting state & dialog, no need for a popover
        return (
            <TreeProvider
                field={field}
                component={FieldTreeInput}
                componentProps={{
                    field,
                    translate,
                    updateValue: handleTreeSelectChange,
                    isMulti: true,
                    value: valueAsArray,
                    label: translate(field.title),
                    disabled,
                    dense: true,
                    size,
                    fullWidth,
                    narrowSelection: false,
                }}
            />
        );
    }

    return (
        <>
            <Popover {...menu.menuProps}>
                <PopoverContainer>
                    <Select
                        isClearable
                        menuIsOpen={!disabled}
                        isMulti
                        smallGutters
                        size="small"
                        margin="none"
                        value={selectedOptions}
                        onChange={handleChange}
                        placeholder={`${translate("Search")}...`}
                        isDisabled={disabled}
                        isSearchable={true}
                        components={{
                            Menu: SelectStaticMenuContainer,
                            DropdownIndicator: undefined,
                        }}
                        options={options}
                    />
                </PopoverContainer>
            </Popover>
            <SelectButton
                label={translate(field.title)}
                count={value.length}
                size={size}
                onClick={menu.openMenu}
                aria-expanded={menu.menuProps.open ? "true" : "false"}
                aria-haspopup="true"
                disabled={disabled && value.length === 0}
                fullWidth={fullWidth}
            />
        </>
    );
};

export default React.memo(IdeaFieldFilter);
