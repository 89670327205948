import {
    FieldDefinition,
    FieldDefinitionsDto,
    FieldTypes,
    IdeaAttributeDto,
    IdeaDto,
    IdeaFieldNames,
    IdeaStatus,
    UserDto,
    effortConverter,
} from "api-shared";
import { memoize } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import IdResolver from "../../../components/IdResolver";
import TableCurrencyCell from "../../../components/table/TableCurrencyCell";
import TableDateCell from "../../../components/table/TableDateCell";
import TableFloatCell from "../../../components/table/TableFloatCell";
import TableIdeaIdCell from "../../../components/table/TableIdeaIdCell";
import TableIdeaTitleCell from "../../../components/table/TableIdeaTitleCell";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { findField, removeDependsFromField } from "../../../lib/fields";
import { replaceImage } from "../../../lib/history";
import { replaceMentionUsers } from "../../../lib/mention";
import { translationKeys } from "../../../translations/main-translations";
import OptionsProvider from "../../OptionsProvider";
import { IdeaEstimate, useIdeaEstimates } from "./useIdeaEstimates";

interface IUseIdeaTableCellRenderersProps {
    fieldDefinitions: FieldDefinitionsDto;
    users: UserDto[];
    attributes: IdeaAttributeDto[];
}

export const useIdeaTableCellRenderers = ({ fieldDefinitions, users, attributes }: IUseIdeaTableCellRenderersProps) => {
    const { t: translate } = useTranslation();

    const { potentialEstimates, timeEstimates, effortEstimates } = useIdeaEstimates();

    // use one large memo here to avoid having dedicated useCallback/useMemos for each renderer
    // The data dependencies rarely change
    // Inline-function needed, so that dependencies can be checked by linter -> disable cognitive complexity check here
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return useMemo(() => {
        // Renderers that do not depend on extra column/field information
        // Data formatting can be done without additional field information
        const renderIdeaTitleCell = (props: CellProps<IdeaDto>) => <TableIdeaTitleCell {...props} />;

        const renderUser = ({ value, ...props }: CellProps<IdeaDto>) => {
            const user = users.find((user) => user.id === value);
            return <TableUserCell value={user} users={users} {...props} />;
        };

        const renderStatus = ({ value, ...props }: CellProps<IdeaDto>) => {
            let translatedStatus;
            switch (value) {
                case IdeaStatus.CONVERTED:
                    translatedStatus = translate(translationKeys.VDLANG_IDEAS_CONVERTED);
                    break;
                case IdeaStatus.OPEN:
                    translatedStatus = translate(translationKeys.VDLANG_IDEAS_OPEN);
                    break;
                case IdeaStatus.DISCARDED:
                    translatedStatus = translate(translationKeys.VDLANG_IDEAS_DISCARDED);
                    break;
                default:
                    throw new Error(`Translation of idea status "${value as string}" not handled.`);
            }
            return <TableTextCell value={translatedStatus} {...props} />;
        };

        const renderMarkdown = ({ value }: CellProps<IdeaDto>) => (
            <TableTextCell value={replaceImage(replaceMentionUsers(users, value, translate))} />
        );

        // For fields where the time unit is not given in the column name
        const renderTimeEstimateWithUnit = ({ value }: CellProps<IdeaDto>) => <TableTextCell>{effortConverter(value)}</TableTextCell>;

        // Renderers that require additional field information, so they cannot be re-used across multiple columns
        // react-table requires stable references to be performant, so these factories are wrapped with memoize() for simple caching
        // across multiple keys.
        const makeDateRenderer = memoize((field: FieldDefinition) => (props: CellProps<IdeaDto>) => (
            <TableDateCell noTimezone={field.isTimezoneAware === false} {...props} />
        ));

        // For fields where the column name already describes the unit being used (EUR, months, etc.)
        const makeEstimateRenderer = memoize((estimates: IdeaEstimate[]) => ({ value, ...props }: CellProps<IdeaDto>) => {
            const potential = value == null ? "" : (estimates.find((estimate) => estimate.value === value)?.label ?? String(value));
            return <TableTextCell value={potential} {...props} />;
        });

        const makeIdRenderer = memoize((column: string) => {
            const attribute = findField(attributes, fieldDefinitions, column);
            if (attribute == null) {
                return "";
            }

            const field = removeDependsFromField(attribute);

            return ({ value }: CellProps<IdeaDto>) =>
                value == null ? null : (
                    <OptionsProvider field={field} value={value}>
                        <IdResolver values={value} />
                    </OptionsProvider>
                );
        });

        const makeDoubleRenderer = memoize((column: string) => (props: CellProps<IdeaDto>) => {
            const field = findField(attributes, fieldDefinitions, column);
            if (field == null) {
                return "";
            }
            return (
                <TableFloatCell
                    minimumFractionDigits={field.options?.fractionDigits}
                    maximumFractionDigits={field.options?.fractionDigits}
                    {...props}
                />
            );
        });

        return (column: string) => {
            const field = fieldDefinitions[column];
            if (!field) {
                return "";
            }

            switch (field.name) {
                case IdeaFieldNames.DisplayId:
                    return TableIdeaIdCell;
                case IdeaFieldNames.Title:
                    return renderIdeaTitleCell;
                case IdeaFieldNames.PotentialEstimate:
                    return makeEstimateRenderer(potentialEstimates);
                case IdeaFieldNames.TimeEstimate:
                    return makeEstimateRenderer(timeEstimates);
                case IdeaFieldNames.EffortEstimate:
                    return makeEstimateRenderer(effortEstimates);
                case IdeaFieldNames.Status:
                    return renderStatus;
            }

            switch (field.type) {
                case FieldTypes.Currency:
                    return TableCurrencyCell;
                case FieldTypes.Date:
                    return makeDateRenderer(field);
                case FieldTypes.Single:
                case FieldTypes.Set:
                case FieldTypes.Users:
                    return makeIdRenderer(column);
                case FieldTypes.User:
                    return renderUser;
                case FieldTypes.Text:
                    return renderMarkdown;
                case FieldTypes.TimeEstimate:
                    return renderTimeEstimateWithUnit;
                case FieldTypes.Double:
                    return makeDoubleRenderer(column);

                default:
                    return TableTextCell;
            }
        };
    }, [users, translate, fieldDefinitions, potentialEstimates, timeEstimates, effortEstimates, attributes]);
};

export default useIdeaTableCellRenderers;
