import { Popover, formControlClasses, styled } from "@mui/material";
import { EstimatesValue } from "api-shared";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import Select from "../../components/input/select/Select";
import SelectButton from "../../components/input/select/SelectButton";
import SelectStaticMenuContainer from "../../components/input/select/components/SelectStaticMenu";
import { Option } from "../../components/input/select/types";
import useMenu from "../../hooks/useMenu";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaEstimates } from "./hooks";

const PopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(30),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

type Props = {
    variant: "potential" | "time" | "effort";
    value: (EstimatesValue | null)[];
    onChange?: (value: (EstimatesValue | null)[]) => void;
    size?: "small" | "medium";
    disabled?: boolean;
    fullWidth?: boolean;
};

export const IdeaEstimateFilter = ({ variant, value, onChange, size = "medium", disabled = false, fullWidth }: Props) => {
    const { t: translate } = useTranslation();
    const menu = useMenu();
    const { potentialEstimates, timeEstimates, effortEstimates } = useIdeaEstimates();

    let label = "";
    let options: Option<number | null>[] = [{ value: null, label: translate(translationKeys.VDLANG_IDEAS_FIELDS_NOT_SET) }];

    switch (variant) {
        case "effort":
            label = translationKeys.VDLANG_IDEAS_SEARCH_EFFORT;
            options = [...options, ...effortEstimates];
            break;
        case "potential":
            label = translationKeys.VDLANG_IDEAS_SEARCH_POTENTIAL;
            options = [...options, ...potentialEstimates];
            break;
        case "time":
            label = translationKeys.VDLANG_IDEAS_SEARCH_TIME;
            options = [...options, ...timeEstimates];
            break;
    }

    const selectedOptions = options.filter((option) => value.includes(option.value));

    const handleChange = (newValue: OnChangeValue<Option, true>) => {
        onChange?.(newValue.map((option) => option.value));
    };

    return (
        <>
            <Popover {...menu.menuProps}>
                <PopoverContainer>
                    <Select
                        isClearable
                        menuIsOpen={!disabled}
                        isSearchable
                        isMulti
                        size="small"
                        smallGutters
                        value={selectedOptions}
                        options={options}
                        onChange={handleChange}
                        placeholder={`${translate("Search")}...`}
                        margin="none"
                        isDisabled={disabled}
                        components={{
                            Menu: SelectStaticMenuContainer,
                            DropdownIndicator: undefined,
                        }}
                    />
                </PopoverContainer>
            </Popover>
            <SelectButton
                label={translate(label)}
                count={value.length}
                size={size}
                onClick={menu.openMenu}
                aria-expanded={menu.menuProps.open ? "true" : "false"}
                aria-haspopup="true"
                disabled={disabled && value.length === 0}
                fullWidth={fullWidth}
            />
        </>
    );
};
