import EventIcon from "@mui/icons-material/EventRounded";
import { Box, Stack, Typography } from "@mui/material";

interface IEstimatesGrid {
    potentialValue: string;
    timeValue?: string;
    effortValue?: string;
    showTimeValue: boolean;
}

const EstimatesGrid = ({ potentialValue, timeValue, effortValue, showTimeValue }: IEstimatesGrid) => (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Typography variant="body2" color="natureGreen.dark">
            {potentialValue}
        </Typography>
        <Box flexGrow={1} />
        {showTimeValue && <EventIcon color="action" fontSize="small" />}
        <Typography color="textSecondary">{showTimeValue ? timeValue : effortValue}</Typography>
    </Stack>
);

export default EstimatesGrid;
