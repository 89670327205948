import { Box, ClickAwayListener, List, ListItemButton, Paper, Stack, styled, Typography } from "@mui/material";
import { IdeaDto, IdeaStatus, IdeaXAxis } from "api-shared";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import InlineChip from "../../../components/InlineChip";
import { UnstyledLink } from "../../../components/Link";
import { RouteFor } from "../../../lib/routes";
import { translationKeys } from "../../../translations/main-translations";
import EstimatesGrid from "../EstimatesGrid";
import { useIdeaEstimateConversion } from "../hooks";
import IdeaIdChip from "../IdeaIdChip";
import { IdeaProperties } from "./IdeaMatrix";

interface ExtendedTooltipProps {
    isVisible: boolean;
    toggleVisibility: () => void;
    targetClassName: string;
    selectedXAxis: IdeaXAxis;
}

const EstimatesWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const IdeaList = styled(List)(({ theme }) => ({
    overflow: "auto",
    maxHeight: 400,
    pointerEvents: "auto",
}));

const IdeaButton = styled(ListItemButton)(({ theme }) => ({
    justifyContent: "space-between",
    gap: theme.spacing(2),
    padding: theme.spacing(0.625, 2),
}));

const IdeaTitle = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    width: 200,
}));

const IdeaMatrixScatterTooltip = ({
    payload,
    isVisible,
    toggleVisibility,
    targetClassName,
    selectedXAxis,
}: TooltipProps<ValueType, NameType> & ExtendedTooltipProps) => {
    const { t: translate } = useTranslation();
    const getIdeaConversionValues = useIdeaEstimateConversion();

    if (!isVisible) {
        return null;
    }

    const numberOfIdeas = payload?.find((pl) => pl.dataKey === IdeaProperties.NUMBER_OF_IDEAS);
    const timeEstimate = payload?.find((pl) => pl.dataKey === IdeaProperties.TIME);
    const potentialEstimate = payload?.find((pl) => pl.dataKey === IdeaProperties.POTENTIAL);
    const effortEstimate = payload?.find((pl) => pl.dataKey === IdeaProperties.EFFORT);

    if (
        (timeEstimate === undefined && effortEstimate === undefined) ||
        (timeEstimate?.value === undefined && effortEstimate?.value === undefined) ||
        potentialEstimate === undefined ||
        potentialEstimate.value === undefined
    ) {
        return null;
    }

    const { potentialValue, timeValue, effortValue } = getIdeaConversionValues({
        potentialEstimateValue: potentialEstimate.value as number,
        timeEstimateValue: timeEstimate?.value as number,
        effortEstimateValue: effortEstimate?.value as number,
    });

    const handleClickAway = (e: MouseEvent | TouchEvent) => {
        if (e.target instanceof Element && e.target.classList.contains(targetClassName)) {
            // to not disable tooltip if the click away targets another scatter point
            return;
        }
        toggleVisibility();
    };

    const showTimeValue = selectedXAxis === IdeaXAxis.TIME && timeEstimate !== undefined;

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Paper variant="elevation" elevation={8} role="dialog">
                <Stack>
                    <EstimatesWrapper>
                        <EstimatesGrid
                            potentialValue={potentialValue}
                            timeValue={timeValue}
                            effortValue={effortValue}
                            showTimeValue={showTimeValue}
                        />
                    </EstimatesWrapper>
                    <IdeaList>
                        {numberOfIdeas?.payload.ideas.map((idea: IdeaDto) => (
                            <UnstyledLink
                                to={RouteFor.opportunities.forId(idea.id)}
                                key={idea.id}
                                onClick={() => toggleVisibility()}
                                sx={{ opacity: idea.status === IdeaStatus.DISCARDED ? 0.5 : 1 }}
                            >
                                <IdeaButton>
                                    <Stack direction="row" gap={2} alignItems="center">
                                        <IdeaIdChip ideaId={idea.displayId} />
                                        <IdeaTitle noWrap variant="subtitle2">
                                            {idea.title}
                                        </IdeaTitle>
                                    </Stack>
                                    {idea.referencedMeasure !== null && (
                                        <InlineChip
                                            label={translate(translationKeys.VDLANG_IDEAS_CONVERTED)}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                </IdeaButton>
                            </UnstyledLink>
                        ))}
                    </IdeaList>
                </Stack>
            </Paper>
        </ClickAwayListener>
    );
};

export default IdeaMatrixScatterTooltip;
