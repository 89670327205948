import { styled } from "@mui/material";
import DefaultButton from "./DefaultButton";

const OutlinedIconButton = styled(DefaultButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.border.main,
    color: theme.palette.fontLightGrey.main,
    minWidth: 0,
    paddingInline: theme.spacing(0.75),
    "&:hover": {
        borderColor: theme.palette.border.main,
    },
}));

export default OutlinedIconButton;
